import React, { useState } from 'react';
import "./styles/Home.css";
import Hero from './components/Hero'
import Footer from './components/Footer'


export default function Home() {

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const toggleMenu = () => {
    console.log("Before toggle:", isMenuOpen); // Check current state
    setIsMenuOpen(!isMenuOpen);
  };
  
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className="home-purple-gradient">
      {/* Scroll-top */}
      <button className="scroll-top scroll-to-target" onClick={scrollToTop}>
          <i className="fas fa-angle-up"></i>
      </button>
      {/* Scroll-top-end */}
      <header id="header" className="header-layout1">
        <div id="sticky-header" className="menu-area transparent-header">
          <div className="container custom-container">
            <div className="row">
              <div className="col-12">
                <div className="menu-wrap">
                  <nav className="menu-nav">
                    <div className="logo">
                      <a href="https://epsiloan.xyz/">
                        <img src="https://epsiloan.xyz/photos/EPSILOGO.png" style={{ height: '45px' }} alt="EpsiLoan Logo" />
                      </a>
                    </div>
                    <div className="navbar-wrap main-menu d-none d-lg-flex">
                    </div>
                    <div className="header-action">
                      <ul className="list-wrap">
                        <li className="header-login">
                          <a
                            className="btnapp"
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                          >
                            {isHovered ? "Coming Soon" : "Open App"}
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="mobile-nav-toggler" onClick={toggleMenu}>
                      <i className="fas fa-bars"></i>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <Hero />
      <Footer />
    </div>
  );
}
