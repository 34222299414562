import React from 'react';
import '../styles/Home.css';
import Tvl2 from './Tvl2';


const Hero = () => {

    return (
        <div className="hero-wrapper hero-1">
            <div className="hero-bg-gradient"></div>

            <div className="container">
                <div className="hero-style1">
                    <Tvl2/>
                </div>
            </div>
        </div>
    );
};

export default Hero;
