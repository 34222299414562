import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { EpsiloanAirdropAddress } from './contracts/abis';
import '../styles/Tvl.css';
import EpsiloanAirdropABI from './contracts/EpsiloanAirdropABI.json';

const Tvl2 = () => {
  const [activeTab, setActiveTab] = useState('tab1');
  const [showClaimButtons, setShowClaimButtons] = useState({
    tab1: false,
    tab2: false,
    tab3: false,
    tab4: false,
    tab5: false,
    tab6: false,
    tab7: false,
    tab8: false,
    tab9: false,
    tab10: false,
  });
  const [walletConnected, setWalletConnected] = useState(false);
  const [correctNetwork, setCorrectNetwork] = useState(false);
  const [claimedRewards, setClaimedRewards] = useState({});
  const [messages, setMessages] = useState({});
  const [totalDiamonds, setTotalDiamonds] = useState(0);
  const [referralLink, setReferralLink] = useState('');
  const [referralRewards, setReferralRewards] = useState(0);
  const [referralAddress, setReferralAddress] = useState('');
  const [tweetLink, setTweetLink] = useState('');
  const [telegramLink, setTelegramLink] = useState('');
  const [youtubeLink, setYoutubeLink] = useState('');
  const [tiktokLink, setTiktokLink] = useState('');
  const [email, setEmail] = useState('');
  const [linkErrorMessage, setLinkErrorMessage] = useState('');

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    if (linkErrorMessage) {
      setLinkErrorMessage('');
    }
  };

  const handleStartQuest = () => {
    if (!email) {
      setLinkErrorMessage('Email is required.');
    } else if (!validateEmail(email)) {
      setLinkErrorMessage('Invalid Email format.');
    } else {
      setLinkErrorMessage('');
      // Send email to PHP endpoint
      fetch('https://quest.epsiloan.xyz/startquest.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          email: email
        })
      })
        .then(response => response.json())
        .then(data => {
          if (data.status === 'success') {
            handleNextClick();
          } else {
            setLinkErrorMessage(data.message);
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          setLinkErrorMessage('An error occurred. Please try again later.');
        });
    }
  };

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const handleNextClick = () => {
    const nextTabIndex = parseInt(activeTab.replace('tab', '')) + 1;
    if (nextTabIndex <= 10) {
      setActiveTab(`tab${nextTabIndex}`);
    }
  };

  const handleSkipClick = () => {
    handleNextClick();
  };

  const handleTwitterFollowClick = () => {
    window.open(
      'https://twitter.com/intent/follow?region=follow_link&screen_name=epsiloan_xyz',
      'TwitterPopup',
      'width=600,height=800,scrollbars=no,resizable=no'
    );
    setTimeout(() => {
      setShowClaimButtons((prev) => ({ ...prev, tab1: true }));
    }, 5000);
  };

  const handleTwitterLikeClick = () => {
    window.open(
      'https://twitter.com/intent/like?tweet_id=1796882923120918865',
      'TwitterPopup',
      'width=600,height=800,scrollbars=no,resizable=no'
    );
    setTimeout(() => {
      setShowClaimButtons((prev) => ({ ...prev, tab2: true }));
    }, 5000);
  };

  const handleTwitterReplyClick = () => {
    window.open(
      'https://twitter.com/intent/post?in_reply_to=1796882923120918865&text=+',
      'TwitterPopup',
      'width=600,height=800,scrollbars=no,resizable=no'
    );
    setTimeout(() => {
      setShowClaimButtons((prev) => ({ ...prev, tab3: true }));
    }, 5000);
  };

  const handleTwitterRetweetClick = () => {
    window.open(
      'https://twitter.com/intent/retweet?tweet_id=1796882923120918865',
      'TwitterPopup',
      'width=600,height=800,scrollbars=no,resizable=no'
    );
    setTimeout(() => {
      setShowClaimButtons((prev) => ({ ...prev, tab4: true }));
    }, 5000);
  };

  const handleTwitterAboutClick = () => {
    const urls = [
      'https://x.com/intent/post?text=The+journey+of+a+thousand+miles+begins+with+a+single+step.+Thrilled+to+embark+on+this+new+quest+in+the+world+of+crypto+with+%40EpsiLoan_XYZ%21+%F0%9F%9A%80%F0%9F%92%A1+%23EpsiLoan+%24ELN',
      'https://x.com/intent/post?text=Building+the+future+of+finance%2C+one+block+at+a+time.+Join+us+on+this+exciting+crypto+quest%21+%F0%9F%94%97+quest.epsiloan.xyz+%40epsiloan_xyz+%23EpsiLoan+%24ELN',
      'https://x.com/intent/post?text=We%27re+on+a+mission+to+decentralize+the+future.+Stay+tuned+for+groundbreaking+updates+from+Epsiloan+%F0%9F%8C%90+quest.epsiloan.xyz+%40epsiloan_xyz+%23EpsiLoan+%24ELN',
      'https://x.com/intent/post?text=Innovation%2C+transparency%2C+and+trust%3A+the+core+values+driving+our+organic+Epsiarmy.+Ready+for+the+quest+ahead%21+%F0%9F%8C%9F+quest.epsiloan.xyz+%40epsiloan_xyz+%23EpsiLoan+%24ELN',
      'https://x.com/intent/post?text=The+future+is+decentralized.+Proud+to+be+part+of+Epsiloan+that%27s+leading+the+charge%21+%F0%9F%94%A5+quest.epsiloan.xyz+%40epsiloan_xyz+%23EpsiLoan+%24ELN',
      'https://x.com/intent/post?text=Charting+new+territories+in+the+crypto+world.+Exciting+developments+are+on+the+horizon%21+%F0%9F%A7%AD+quest.epsiloan.xyz+%40epsiloan_xyz+%23EpsiLoan+%24ELN',
    ];

    const randomUrl = urls[Math.floor(Math.random() * urls.length)];

    window.open(
      randomUrl,
      'TwitterPopup',
      'width=600,height=800,scrollbars=no,resizable=no'
    );
    setTimeout(() => {
      setShowClaimButtons((prev) => ({ ...prev, tab5: true }));
    }, 5000);
  };

  const handleTelegramClick = () => {
    window.open(
      'https://t.me/EpsiLoan',
      'TelegramPopup',
      'width=600,height=800,scrollbars=no,resizable=no'
    );
    setTimeout(() => {
      setShowClaimButtons((prev) => ({ ...prev, tab6: true }));
    }, 5000);
  };

  const handleYouTubeClick = () => {
    window.open(
      'https://www.youtube.com/',
      'YoutubePopup',
      'width=600,height=800,scrollbars=no,resizable=no'
    );
    setTimeout(() => {
      setShowClaimButtons((prev) => ({ ...prev, tab7: true }));
    }, 5000);
  };

  const handleTikTokClick = () => {
    window.open(
      'https://www.tiktok.com/',
      'TiktokPopup',
      'width=600,height=800,scrollbars=no,resizable=no'
    );
    setTimeout(() => {
      setShowClaimButtons((prev) => ({ ...prev, tab8: true }));
    }, 5000);
  };

  const claimTokens = async (claimFunction, tabKey) => {
    try {
      console.log(`Claiming ${claimFunction} for ${tabKey}`);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const airdropContract = new ethers.Contract(EpsiloanAirdropAddress, EpsiloanAirdropABI, signer);
      const tx = await airdropContract[claimFunction]();
      await tx.wait();
      setMessages((prevMessages) => ({
        ...prevMessages,
        [tabKey]: 'Claimed successfully!'
      }));
      setClaimedRewards((prevRewards) => ({
        ...prevRewards,
        [tabKey]: true
      }));
    } catch (error) {
      console.error(`Error claiming ${claimFunction} for ${tabKey}:`, error);
      setMessages((prevMessages) => ({
        ...prevMessages,
        [tabKey]: 'Failed to claim tokens.'
      }));
    } finally {
      updateTotalDiamonds();
      fetchReferralRewards();
    }
  };

  const connectWallet = async () => {
    try {
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      setWalletConnected(true);
      checkNetwork();
    } catch (error) {
      console.error('Error connecting wallet:', error);
      setMessages((prevMessages) => ({
        ...prevMessages,
        wallet: 'Failed to connect wallet.'
      }));
    }
  };

  const checkNetwork = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const network = await provider.getNetwork();
    if (network.chainId !== 42161) {
      setCorrectNetwork(false);
      setMessages((prevMessages) => ({
        ...prevMessages,
        network: 'Please connect to the Arbitrum network.'
      }));
    } else {
      setCorrectNetwork(true);
    }
  };

  const switchNetwork = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0xA4B1' }],
      });
      setCorrectNetwork(true);
    } catch (error) {
      console.error('Error switching network:', error);
      setMessages((prevMessages) => ({
        ...prevMessages,
        network: 'Failed to switch network.'
      }));
    }
  };

  const updateTotalDiamonds = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const airdropContract = new ethers.Contract(EpsiloanAirdropAddress, EpsiloanAirdropABI, signer);
      const balance = await airdropContract.getTotalDiamonds(signer.getAddress());
      setTotalDiamonds(balance.toNumber());
    } catch (error) {
      console.error('Error fetching total diamonds:', error);
      setMessages((prevMessages) => ({
        ...prevMessages,
        total: 'Failed to fetch total diamonds.'
      }));
    }
  };

  const fetchReferralRewards = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const airdropContract = new ethers.Contract(EpsiloanAirdropAddress, EpsiloanAirdropABI, signer);
      const rewards = await airdropContract.referralRewards(await signer.getAddress());
      setReferralRewards(ethers.utils.formatEther(rewards));
    } catch (error) {
      console.error('Error fetching referral rewards:', error);
      setMessages((prevMessages) => ({
        ...prevMessages,
        referral: 'Failed to fetch referral rewards.'
      }));
    }
  };

  const claimReferralRewards = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const airdropContract = new ethers.Contract(EpsiloanAirdropAddress, EpsiloanAirdropABI, signer);
      const tx = await airdropContract.claimReferralReward();
      await tx.wait();
      setMessages((prevMessages) => ({
        ...prevMessages,
        referral: 'Referral rewards claimed successfully!'
      }));
      fetchReferralRewards();
    } catch (error) {
      console.error('Error claiming referral rewards:', error);
      setMessages((prevMessages) => ({
        ...prevMessages,
        referral: 'Failed to claim referral rewards.'
      }));
    }
  };

  const generateReferralLink = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const address = await signer.getAddress();
      const referralUrl = `${window.location.origin}/?ref=${address}`;
      setReferralLink(referralUrl);
    } catch (error) {
      console.error('Error generating referral link:', error);
      setMessages((prevMessages) => ({
        ...prevMessages,
        referral: 'Failed to generate referral link.'
      }));
    }
  };

  const submitReferralLink = async () => {
    try {
      if (!referralAddress.startsWith('http')) {
        setMessages((prevMessages) => ({
          ...prevMessages,
          referral: 'Invalid referral link format.'
        }));
        return;
      }
      const url = new URL(referralAddress);
      const urlParams = new URLSearchParams(url.search);
      const refAddress = urlParams.get('ref');
      if (ethers.utils.isAddress(refAddress)) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const airdropContract = new ethers.Contract(EpsiloanAirdropAddress, EpsiloanAirdropABI, signer);
        const tx = await airdropContract.useReferral(refAddress);
        await tx.wait();
        setMessages((prevMessages) => ({
          ...prevMessages,
          referral: 'Referral link submitted successfully!'
        }));
        fetchReferralRewards();
      } else {
        setMessages((prevMessages) => ({
          ...prevMessages,
          referral: 'Invalid referral link.'
        }));
      }
    } catch (error) {
      console.error('Error submitting referral link:', error);
      setMessages((prevMessages) => ({
        ...prevMessages,
        referral: 'Failed to submit referral link.'
      }));
    }
  };

  const validateLink = (link, platform) => {
    let valid = false;
    switch (platform) {
      case 'youtube':
        valid = link.startsWith('https://www.youtube.com/watch?v=') || link.startsWith('https://www.youtube.com/shorts/');
        break;
      case 'telegram':
        valid = link.startsWith('https://t.me/EpsiLoan/');
        break;
      case 'twitter':
        valid = link.startsWith('https://twitter.com/') || link.startsWith('https://x.com/');
        break;
      case 'tiktok':
        valid = link.startsWith('https://www.tiktok.com/');
        break;
      default:
        break;
    }
    return valid;
  };

  const handleTweetLinkChange = (event) => {
    setTweetLink(event.target.value);
    if (event.target.value) {
      setLinkErrorMessage('');
    }
  };

  const handleTelegramLinkChange = (event) => {
    setTelegramLink(event.target.value);
    if (event.target.value) {
      setLinkErrorMessage('');
    }
  };

  const handleYoutubeLinkChange = (event) => {
    setYoutubeLink(event.target.value);
    if (event.target.value) {
      setLinkErrorMessage('');
    }
  };

  const handleTiktokLinkChange = (event) => {
    setTiktokLink(event.target.value);
    if (event.target.value) {
      setLinkErrorMessage('');
    }
  };

  const handleClaimDailyTweet = () => {
    if (!validateLink(tweetLink, 'twitter')) {
      setLinkErrorMessage('Invalid link.');
    } else {
      claimTokens('claimDailyTweet', 'tab6');
      setMessages((prevMessages) => ({
        ...prevMessages,
        tab6: 'Link submitted successfully.',
      }));
    }
  };

  const handleClaimDailyTelegram = () => {
    if (!validateLink(telegramLink, 'telegram')) {
      setLinkErrorMessage('Invalid link.');
    } else {
      claimTokens('claimDailyTelegram', 'tab7');
      setMessages((prevMessages) => ({
        ...prevMessages,
        tab7: 'Link submitted successfully.',
      }));
    }
  };

  const handleClaimYoutubePost = () => {
    if (!validateLink(youtubeLink, 'youtube')) {
      setLinkErrorMessage('Invalid link.');
    } else {
      claimTokens('claimYouTubePost', 'tab8');
      setMessages((prevMessages) => ({
        ...prevMessages,
        tab8: 'Link submitted successfully.',
      }));
    }
  };

  const handleClaimTiktokPost = () => {
    if (!validateLink(tiktokLink, 'tiktok')) {
      setLinkErrorMessage('Invalid link.');
    } else {
      claimTokens('claimTikTokPost', 'tab9');
      setMessages((prevMessages) => ({
        ...prevMessages,
        tab9: 'Link submitted successfully.',
      }));
    }
  };

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on('chainChanged', checkNetwork);
      window.ethereum.on('accountsChanged', checkNetwork);
    }
    checkNetwork();
    if (walletConnected) {
      updateTotalDiamonds();
      fetchReferralRewards();
    }
  }, [walletConnected]);

  return (
    <div className="hero-countdown-wrap">
      <div className="working-message">
        For a smoother and stress free experience, we advise using the browser version on a desktop or laptop machine.
      </div>
      <h2 className="hero-countdown-wrap-title pt-70">Choose Rich with EpsiLoan! <br />Diamonds Await - Join & Earn!</h2>
      <div className="separator"></div>
      <p className="seedsubtitle pt-10 pb-20">
      </p>

      <div className="tabs-container">
        <div className="tabs">
          {[...Array(10)].map((_, index) => (
            <button
              key={index}
              className={`tab-button ${activeTab === `tab${index + 1}` ? 'active' : ''}`}
              onClick={() => handleTabClick(`tab${index + 1}`)}
            >
              {index + 1}
            </button>
          ))}
        </div>

        <div className="tab-content pt-50 pb-50">
          {activeTab === 'tab1' && (
            <div className="cardtabs active">
              <div className='tcard'>
                <p className="follow">🚀 Embark on an Epic Quest with EpsiLoan Protocol! </p>
                <div className="separator"></div>
                <div className='tcard1'>
                  <p className='followsubtitle'>Being part of our vibrant community guarantees rewards 💰 but it's the engagement, creativity,<br />hustle, and proactive spirit that truly sets you apart to reap big.</p><br />
                  <p className='followsubtitle'>Let's reignite the true spirit of community, empower our users, and bring fairness back to crypto.<br />That's right – community first, VCs last! 💪</p>
                  <p className='followsubtitle'>Let's forge financial self-sovereignty together with the power of great memetics.<br />🌐With EpsiLoan, your success is our success; if We win, you win! 🚀</p><br />
                  <p className='followsubtitle'>Quests range from super easy to moderate, and rewards are aligned accordingly.<br />Some tasks can be performed every 24 hours.</p><br />
                </div>
                <div className="submitcard">
                  <p>👨‍👩‍👧 Use your referral link to onboard your friends, spread the word, make our community great<br />and climb the leaderboard!<br /><br />
                    🏆Top 100 referrers will share a pool of $50K+ but Gigachad referrers with over 1,000 referees<br />will get an extra $10k for this achievement.</p>
                  <p>Together, We are Legion! Together, We are EpsiLoan! 🤝</p>
                </div>
                <div className='tcard2'><br />
                  <p className='followsubtitle'>‼️ <b>Note:</b> Don't try to sybil attack, We will verify for bots and fake/multiple accounts<br />and disqualify the entire nest. Heck, We'll even implement bounties for reporting sybils.</p><br />
                  <p className='followsubtitle'>‼️ <b>Note 2:</b> You'll need a bit of ETH on the Arbitrum network in order to claim your Diamonds.<br />When the time is right, your Diamonds will shine bright! 💎</p>
                </div>
              </div>
              <div className="separator"></div>
              <div className="btnswrap">

                <div>
                  <div className="referral-input-section pt-30">
                    <input
                      type="text"
                      placeholder="Enter your email"
                      value={email}
                      onChange={handleEmailChange}
                      required
                    />
                    <button
                      type="submit"
                      onClick={handleStartQuest}
                      enable={!email}
                    >
                      Start Quest
                    </button>
                    {linkErrorMessage && <div className="message my-message">{linkErrorMessage}</div>}
                  </div>
                </div>
              </div>
            </div>
          )}
          {activeTab === 'tab2' && (
            <div className="cardtabs active">
              <div className='tcard'>
                <p className="follow">Follow the @Epsiloan_xyz - X/Twitter account</p>
                <div className="separator"></div>
                <div className='tcard1'>
                  <p className='followsubtitle'>Easy first Diamonds to earn. Just follow our account to get your first Diamonds on the board.</p>
                  <p className='followsubtitle'>Got a referral link? You're in for a treat! Just paste it in the box below and let the diamonds flow.</p>
                </div>
                <div className="submitcard">
                  <div className="submitcardtitle"><u>How to Submit?</u></div>
                  <p>1. Open profile in Twitter</p>
                  <p>2. Follow @Epsiloan_xyz</p>
                  <p>3. Return here and click "Next".</p>
                </div>
              </div>
              <div className="separator"></div>
              {!showClaimButtons.tab1 && (
                <button className="button-30" onClick={handleTwitterFollowClick}>
                  Follow Twitter
                </button>
              )}
              {messages[activeTab] && <div className="message">{messages[activeTab]}</div>}
              <div>
                <div className="referral-input-section pt-30">
                  <input
                    type="text"
                    placeholder="Enter referral link"
                    value={referralAddress}
                    onChange={(e) => setReferralAddress(e.target.value)}
                  />
                  <button type="submit" onClick={submitReferralLink}>
                    Submit
                  </button>
                  {messages.referral && <div className="message my-message">{messages.referral}</div>}
                </div>
                <div className="separator"></div>
                <p className="norefferal"><b>No referral link?</b> Ask our community for one!<br />It's mutually beneficial to use it; otherwise, you can just leave it empty.</p>
              </div>
              <div className="separator"></div>
              <div className="btnswrap">
                <div className="skipbtn" onClick={handleSkipClick}>
                  Skip
                </div>
                <div className="nextbtn" onClick={handleNextClick}>
                  Next
                </div>
              </div>
            </div>
          )}
          {activeTab === 'tab3' && (
            <div className="cardtabs active">
              <div className='tcard'>
                <p className="follow">Like our tweet</p>
                <div className="separator"></div>
                <div className='tcard1'>
                  <p className='followsubtitle'>We like you. Show us that you like us too 😉</p>
                </div>
                <div className="submitcard">
                  <div className="submitcardtitle"><u>How to Submit?</u></div>
                  <p>1. Open Twitter</p>
                  <p>2. Like the tweet</p>
                  <p>3. Return here and click "Next".</p>
                </div>
              </div>
              <div className="separator"></div>
              {!showClaimButtons.tab2 && (
                <button className="button-30" onClick={handleTwitterLikeClick}>
                  Like our tweet
                </button>
              )}
              {messages[activeTab] && <div className="message">{messages[activeTab]}</div>}
              <div className="separator"></div>
              <div className="btnswrap">
                <div className="skipbtn" onClick={handleSkipClick}>
                  Skip
                </div>
                <div className="nextbtn" onClick={handleNextClick}>
                  Next
                </div>
              </div>
            </div>
          )}
          {activeTab === 'tab4' && (
            <div className="cardtabs active">
              <div className='tcard'>
                <p className="follow">Reply to our tweet</p>
                <div className="separator"></div>
                <div className='tcard1'>
                  <p className='followsubtitle'>Reply to our tweet with something extremely intelligent, funny, or stupid — its your choice.</p>
                  <p className='followsubtitle'>Your posts/replies can be in any language, but please tag us appropriately:<br /><br /><b>#EPSILOAN, $ELN, or @EPSILOAN_XYZ</b></p>
                </div>
                <div className="submitcard">
                  <div className="submitcardtitle"><u>How to Submit?</u></div>
                  <p>1. Open profile in Twitter</p>
                  <p>2. Reply to this tweet</p>
                  <p>3. Return here and click "Next".</p>
                </div>
              </div>
              <div className="separator"></div>
              {!showClaimButtons.tab3 &&  (
                <button className="button-30" onClick={handleTwitterReplyClick}>
                  Reply to our tweet
                </button>
              )}
              {messages[activeTab] && <div className="message">{messages[activeTab]}</div>}
              <div className="separator"></div>
              <div className="btnswrap">
                <div className="skipbtn" onClick={handleSkipClick}>
                  Skip
                </div>
                <div className="nextbtn" onClick={handleNextClick}>
                  Next
                </div>
              </div>
            </div>
          )}
          {activeTab === 'tab5' && (
            <div className="cardtabs active">
              <div className='tcard'>
                <p className="follow">Retweet our tweet</p>
                <div className="separator"></div>
                <div className='tcard1'>
                  <p className='followsubtitle'>Retweet our tweet. Do it. Press the button. C’mon, please.</p>
                </div>
                <div className="submitcard">
                  <div className="submitcardtitle"><u>How to Submit?</u></div>
                  <p>1. Open profile in Twitter</p>
                  <p>2. Retweet the tweet</p>
                  <p>3. Return here and click "Next".</p>
                </div>
              </div>
              <div className="separator"></div>
              {!showClaimButtons.tab4 && (
                <button className="button-30" onClick={handleTwitterRetweetClick}>
                  Retweet our tweet
                </button>
              )}
              {messages[activeTab] && <div className="message">{messages[activeTab]}</div>}
              <div className="separator"></div>
              <div className="btnswrap">
                <div className="skipbtn" onClick={handleSkipClick}>
                  Skip
                </div>
                <div className="nextbtn" onClick={handleNextClick}>
                  Next
                </div>
              </div>
            </div>
          )}
          {activeTab === 'tab6' && (
            <div className="cardtabs active">
              <div className='tcard'>
                <p className="follow"> Your Daily Task - Tweet </p>
                <div className="separator"></div>
                <div className='tcard1'>
                  <p className='followsubtitle'>Posting about EPSILOAN every 24H will add to your Diamonds stack.</p>
                </div>
                <div className="submitcard">
                  <div className="submitcardtitle"><u>How to Submit?</u></div>
                  <p>1. Post about EPSILOAN - follow the content guide above!</p>
                  <p>2. Copy & Paste the post link here to verify!</p>
                  <p>3. Submit and Claim 3,500 Diamonds</p>
                </div>
              </div>
              <div className="separator"></div>
              {!showClaimButtons.tab5 && (
                <button className="button-30" onClick={handleTwitterAboutClick}>
                  Tweet About Us
                </button>
              )}
              <div>
                <div className="referral-input-section pt-30">
                  <input
                    type="text"
                    placeholder="Enter post link"
                    value={tweetLink}
                    onChange={handleTweetLinkChange}
                    required
                  />
                  <button
                    type="submit"
                    onClick={handleClaimDailyTweet}
                    enable={!tweetLink}
                  >
                    Submit
                  </button>
                  {linkErrorMessage && <div className="message my-message">{linkErrorMessage}</div>}
                </div>
              </div>
              {messages[activeTab] && <div className="message">{messages[activeTab]}</div>}
              <div className="separator"></div>
              <div className="btnswrap">
                <div className="skipbtn" onClick={handleSkipClick}>
                  Skip
                </div>
                <div className="nextbtn" onClick={handleNextClick}>
                  Next
                </div>
              </div>
            </div>
          )}
          {activeTab === 'tab7' && (
            <div className="cardtabs active">
              <div className='tcard'>
                <p className="follow">Your Daily Task - Telegram Message</p>
                <div className="separator"></div>
                <div className='tcard1'>
                  <p className='followsubtitle'>Enter daily and claim Diamonds</p>
                </div>
                <div className="submitcard">
                  <div className="submitcardtitle"><u>How to Submit?</u></div>
                  <p>1. Be active on Telegram Group</p>
                  <p>2. Copy & Paste Your Message link here to verify</p>
                  <p>3. Submit and Claim 500 Diamonds</p>
                </div>
              </div>
              <div className="separator"></div>
              {!showClaimButtons.tab6 && (
                <button className="button-30" onClick={handleTelegramClick}>
                  Write on Telegram
                </button>
              )}
              <div>
                <div className="referral-input-section pt-30">
                  <input
                    type="text"
                    placeholder="Enter post link"
                    value={telegramLink}
                    onChange={handleTelegramLinkChange}
                    required
                  />
                  <button
                    type="submit"
                    onClick={handleClaimDailyTelegram}
                    enable={!telegramLink}
                  >
                    Submit
                  </button>
                  {linkErrorMessage && <div className="message my-message">{linkErrorMessage}</div>}
                </div>
              </div>
              {messages[activeTab] && <div className="message">{messages[activeTab]}</div>}
              <div className="separator"></div>
              <div className="btnswrap">
                <div className="skipbtn" onClick={handleSkipClick}>
                  Skip
                </div>
                <div className="nextbtn" onClick={handleNextClick}>
                  Next
                </div>
              </div>
            </div>
          )}
          {activeTab === 'tab8' && (
            <div className="cardtabs active">
              <div className='tcard'>
                <p className="follow">Post on YouTube / Youtube Shorts</p>
                <div className="separator"></div>
                <div className='tcard1'>
                  <p className='followsubtitle'>This one is for the createives. Time to turn up the heat, make a YouTube video about <br />#EPSILOAN or $ELN and earn a whopping 50,000 Diamonds bag</p><br />
                  <p className='followsubtitle'>Be sure to use the #EPSILOAN hashtag, and please say nice things about us 🥺🥺🥺</p>
                </div>
                <div className="submitcard">
                  <div className="submitcardtitle"><u>How to Submit?</u></div>
                  <p>1. Open YouTube</p>
                  <p>2. Make a YouTube video / shorts with the following phrases: #EPSILOAN, $ELN</p>
                  <p>3. Copy & Paste the video / shorts link here to verify</p>
                  <p>4. Return here and Claim 50,000 Diamonds</p>
                  <br />
                </div>
              </div>
              <div className="separator"></div>
              {!showClaimButtons.tab7 && (
                <button className="button-yb" onClick={handleYouTubeClick}>
                  Open YouTube
                </button>
              )}
              <div>
                <div className="referral-input-section pt-30">
                  <input
                    type="text"
                    placeholder="Enter post link"
                    value={youtubeLink}
                    onChange={handleYoutubeLinkChange}
                    required
                  />
                  <button
                    type="submit"
                    onClick={handleClaimYoutubePost}
                    enable={!youtubeLink}
                  >
                    Submit
                  </button>
                  {linkErrorMessage && <div className="message my-message">{linkErrorMessage}</div>}
                </div>
              </div>
              {messages[activeTab] && <div className="message">{messages[activeTab]}</div>}
              <div className="separator"></div>
              <div className='submitcardnote'><b>‼️ Note: </b><u>Don't paste random YouTube videos, We'll manually verify the all accounts<br /> who submitted videos and disqualify all cheaters from receiving any rewards.<br />We prefer someone honest with no video submission or a low production<br />video about EpsiLoan, than a cheater with an unrelated submission.</u></div>
              <div className="separator"></div>
              <div className="btnswrap">
                <div className="skipbtn" onClick={handleSkipClick}>
                  Skip
                </div>
                <div className="nextbtn" onClick={handleNextClick}>
                  Next
                </div>
              </div>
            </div>
          )}
          {activeTab === 'tab9' && (
            <div className="cardtabs active">
              <div className='tcard'>
                <p className="follow">Post a TikTok video</p>
                <div className="separator"></div>
                <div className='tcard1'>
                  <p className='followsubtitle'>Are you a video wiz? 🎥 If you've already made a YouTube video, this one's easy!<br />Just port it over to TikTok and claim your reward. </p><br />
                  <p className='followsubtitle'>Vice versa works too – create a TikTok and port it to YouTube.<br />Enjoy earning those 25,000 Diamonds! 💎 #EpsiLoan #ELN $ELN</p>
                </div>
                <div className="submitcard">
                  <div className="submitcardtitle"><u>How to Submit?</u></div>
                  <p>1. Open TikTok</p>
                  <p>2. Make a TikTok video with the following phrases: #EPSILOAN, $ELN</p>
                  <p>3. Copy & Paste the video link here to verify</p>
                  <p>4. Return here and Claim 25,000 Diamonds</p>
                </div>
              </div>
              <div className="separator"></div>
              {!showClaimButtons.tab8 && (
                <button className="button-tt" onClick={handleTikTokClick}>
                  Open TikTok
                </button>
              )}
              <div>
                <div className="referral-input-section pt-30">
                  <input
                    type="text"
                    placeholder="Enter post link"
                    value={tiktokLink}
                    onChange={handleTiktokLinkChange}
                    required
                  />
                  <button
                    type="submit"
                    onClick={handleClaimTiktokPost}
                    enable={!tiktokLink}
                  >
                    Submit
                  </button>
                  {linkErrorMessage && <div className="message my-message">{linkErrorMessage}</div>}
                </div>
              </div>
              {messages[activeTab] && <div className="message">{messages[activeTab]}</div>}
              <div className="separator"></div>
              <div className='submitcardnote'><b>‼️ Note: </b><u>Don't paste random TikTok videos, We'll manually verify the all accounts<br /> who submitted videos and disqualify all cheaters from receiving any rewards.<br />We prefer someone honest with no video submission or a low production<br />video about EpsiLoan, than a cheater with an unrelated submission.</u></div>
              <div className="separator"></div>
              <div className="btnswrap">
                <div className="skipbtn" onClick={handleSkipClick}>
                  Skip
                </div>
                <div className="nextbtn" onClick={handleNextClick}>
                  Next
                </div>
              </div>
            </div>
          )}
          {activeTab === 'tab10' && (
            <div className="cardtabs active">
              <div className='tcard'>
                <p className="follow">Get Referral Link</p>
                <div className="separator"></div>
                <div className='tcard1'>
                  <p className='followsubtitle'>Onboard your friends and share prizes.</p><br />
                  <p className='followsubtitle'>Get 10,000 Diamonds for every Referee that joins our campaign with your link.</p>
                  <p className='followsubtitle'>Referee will automaticaly receive 5,000 Diamonds for using your link.</p><br />
                  <p className='followsubtitle'>You Win, They Win, We Win! 🤝 Together We Are Legion!</p>
                </div>
                <div className="submitcard">
                  <div className="submitcardtitle"><u>How to Submit?</u></div>
                  <p>1. Click the “Get Referral Link” button below to generate your Referral</p>
                  <p>2. Share link to friends</p>
                  <p>3. Claim your rewards ( if any )</p>
                </div>
              </div>
              <div className="separator"></div>
              {!walletConnected ? (
                <button className="button-30" onClick={connectWallet}>
                  Connect Wallet
                </button>
              ) : !correctNetwork ? (
                <button className="button-30" onClick={switchNetwork}>
                  Switch to Arbitrum
                </button>
              ) : (
                <>
                  {!claimedRewards.tab4 ? (
                    <div>
                      {!claimedRewards.tab1 && (
                        <button className="button-30" onClick={() => claimTokens('claimTwitterFollow', 'tab1')}>
                          Claim Rewards For Follow
                        </button>
                      )}
                      {claimedRewards.tab1 && !claimedRewards.tab2 && (
                        <button className="button-30" onClick={() => claimTokens('claimTweetLike', 'tab2')}>
                          Claim Rewards For Like
                        </button>
                      )}
                      {claimedRewards.tab2 && !claimedRewards.tab3 && (
                        <button className="button-30" onClick={() => claimTokens('claimTweetReply', 'tab3')}>
                          Claim Rewards For Reply
                        </button>
                      )}
                      {claimedRewards.tab3 && !claimedRewards.tab4 && (
                        <button className="button-30" onClick={() => claimTokens('claimTweetRetweet', 'tab4')}>
                          Claim Rewards For Retweet
                        </button>
                      )}
                    </div>
                  ) : (
                    <button className="button-30" disabled>
                      Claimed
                    </button>
                  )}
                </>
              )}
              <br /><br />
              <button className="button-rl" onClick={generateReferralLink}>Get Referral Link</button>
              {referralLink && (<div>
                <div className="referral-input-section-ref pt-30">
                  <input type="text" value={referralLink} readOnly />
                  <button type="submit" onClick={() => navigator.clipboard.writeText(referralLink)}>
                    Copy Link
                  </button>
                </div>
              </div>
              )}
              <div className="separator"></div>
              <div className="btnswrap">
                {referralRewards > 0 ? (
                  <button className="button-30" onClick={claimReferralRewards}>
                    Claim {referralRewards} Diamonds
                  </button>
                ) : (
                  <button className="button-30" disabled>
                    No Rewards to Claim
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="separator"></div>
      <p className="seedsubtitle pt-10 pb-50">
        Community is essential to us, and we are committed to providing equal opportunities to all
        who wish to onboard our journey.<br />
        Join us! Together, we are Legion!
      </p>
    </div>
  );
};

export default Tvl2;
