import React from 'react';
import '../styles/Home.css';

const Footer = () => {
    return (
        <footer className="footer-wrapper footer-layout1 position-relative">
        <div className="bg-gradient-1">
            <img src="assets/img/bg-gradient1-1.jpg" alt="img"/>
        </div>
        <div className="container">
            <div className="footer-menu-area">
                <div className="row gy-4 justify-content-between align-items-center">
                    <div className="col-xl-5 col-lg-4">
                        <div className="social-btn justify-content-center justify-content-lg-start">
                            <a href="https://t.me/EpsiLoan">
                                <i className="fab fa-telegram-plane"></i>
                            </a>
                            <a href="https://www.twitter.com/epsiloan_xyz">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M10.0596 7.34522L15.8879 0.570312H14.5068L9.44607 6.45287L5.40411 0.570312H0.742188L6.85442 9.46578L0.742188 16.5703H2.12338L7.4676 10.3581L11.7362 16.5703H16.3981L10.0593 7.34522H10.0596ZM8.16787 9.54415L7.54857 8.65836L2.62104 1.61005H4.74248L8.71905 7.29827L9.33834 8.18405L14.5074 15.5779H12.386L8.16787 9.54449V9.54415Z" fill="currentColor"></path>
                                </svg>
                            </a>
                            <a href="https://t.me/epsiloan_announcements">
                                <i className="fab fa-telegram"></i>
                            </a>
                            <a href="https://epsiloan.medium.com/">
                                <i className="fab fa-medium"></i>
                            </a>
                            <a href="https://epsiloan.gitbook.io/docs">
                                <i className="fa fa-book"></i>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-7 col-lg-8 text-lg-end text-center">
                        <ul className="footer-menu-list">
                            <li className="menu-item-has-children active"></li>
                            <li className=""><a href="https://epsiloan.xyz/" >Home</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className="copyright-wrap text-center text-lg-start">
            <div className="container">
                <div className="row gy-3 justify-content-between align-items-center">
                    <div className="col-lg-6 align-self-center">
                        <p className="copyright-text">Copyright © 2024 <a href=" ">EpsiLoan Protocol.</a> All rights reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    );
}; 

export default Footer;
